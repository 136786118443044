import React, { ReactNode, RefObject } from "react";
import {
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { usePathname } from "next/navigation";
import Link from "modules/common/components/navigation/Link";
import { activeButton } from "utils/utils";

interface DropdownMenuProps {
  buttonRef: RefObject<HTMLButtonElement>;
  isOpen: boolean;
  toggleMenu: () => void;
  closeMenu: (event: Event | React.SyntheticEvent) => void;
  menuItems: { href: string; text: string; icon?: ReactNode }[];
  handleListKeyDown: (event: React.KeyboardEvent) => void;
  placement?: PopperPlacementType;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  buttonRef,
  isOpen,
  toggleMenu,
  closeMenu,
  menuItems,
  handleListKeyDown,
  placement = "bottom-end",
}) => {
  const theme = useTheme();
  const pathName = usePathname();

  return (
    <Popper
      open={isOpen}
      anchorEl={buttonRef.current}
      placement={placement}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-end" ? "right top" : "right bottom",
          }}
        >
          <Paper
            sx={{
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.primary.main,
            }}
          >
            <ClickAwayListener onClickAway={closeMenu}>
              <MenuList
                autoFocusItem={isOpen}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                {menuItems.map((item, index) => (
                  <MenuItem
                    key={index}
                    component={Link}
                    href={item.href}
                    onClick={(e) => closeMenu(e)}
                    sx={{
                      backgroundColor: activeButton(pathName, item.href)
                        ? theme.palette.primary.main
                        : theme.palette.background.paper,
                      color: activeButton(pathName, item.href)
                        ? theme.palette.background.paper
                        : theme.palette.primary.main,
                      "&:hover": {
                        backgroundColor: activeButton(pathName, item.href)
                          ? theme.palette.primary.main
                          : theme.palette.action.hover,
                        color: activeButton(pathName, item.href)
                          ? theme.palette.background.paper
                          : theme.palette.primary.main,
                      },
                    }}
                  >
                    {item.icon && (
                      <ListItemIcon
                        sx={{
                          color: activeButton(pathName, item.href)
                            ? theme.palette.background.paper
                            : theme.palette.primary.main,
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                    )}
                    <ListItemText>{item.text}</ListItemText>
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
