import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile","__experimental_GoogleOneTap"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/mana-font/css/mana.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/modules/common/components/ClerkProviderWrapper.tsx\",\"import\":\"Source_Sans_3\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\"}],\"variableName\":\"sourceSansPro\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/keyrune/css/keyrune.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/modules/common/components/Providers/RecoilProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/modules/common/components/Providers/RQProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/modules/common/components/skeletons/common/AppBarSkeleton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/modules/common/components/ThemeRegistry/ThemeRegistry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppBarComponent"] */ "/vercel/path0/src/modules/layout/AppBarComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppLayout"] */ "/vercel/path0/src/modules/layout/AppLayout.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/foil.css");
