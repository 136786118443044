import { useQuery } from "@tanstack/react-query";
import { ShortUser } from "modules/common/model/models";
import { QUERIES } from "modules/common/query";
import { useAuth } from "@clerk/nextjs";
import { API_ROUTES } from "utils/api-route.constants";
import { useFetchWrapper } from "modules/common/hooks/useFetchWrapper";

export const useAppUser = () => {
  const fetch = useFetchWrapper();
  const { userId } = useAuth();

  const getAppUser = async (username: string, userId?: string) => {
    const q = `?username=${username}&user_id=${userId || ""}`;
    return await fetch
      .get(API_ROUTES.USERS.GET + q, null)
      .then((res: ShortUser) => res);
  };

  const {
    data: appUser,
    isPending,
    isError,
  } = useQuery<ShortUser>({
    queryKey: [QUERIES.APP_USER, userId],
    queryFn: () => getAppUser("", userId!),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!userId,
  });

  return {
    appUser,
    isPending,
    isError,
  };
};
