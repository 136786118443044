"use client";
import React from "react";
import {
  Box,
  IconButton,
  Skeleton,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import { usePathname } from "next/navigation";
import { MtgGuruLogoIcon } from "modules/common/components/Icons/MTGGuruIcon";
import { BRAND_NAME } from "utils/constants";

const AppBarSkeleton = () => {
  const pathName = usePathname();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const isHome = pathName === "/";
  return (
    <MuiAppBar
      position="sticky"
      sx={{
        bgcolor: isHome
          ? "transparent"
          : (theme: Theme) => theme.palette.background.paper,
        ...(isHome ? { boxShadow: "none" } : {}),
      }}
    >
      <Container
        maxWidth={"xl"}
        sx={{
          bgcolor: "#0d2a43",
          position: "relative",
          ...(isHome ? { boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)" } : {}),

          "&::before": {
            content: { xs: undefined, md: '""' },
            position: "absolute",
            top: 0,
            left: -64,
            height: "64px",
            width: "64px", // Adjust as needed
            backgroundColor: "#0d2a43",
            WebkitMask:
              "radial-gradient(farthest-side at bottom left, transparent 99%, #fff 100%)",
            mask: "radial-gradient(farthest-side at bottom left, transparent 99%, #fff 100%)",
            ...(isHome
              ? { filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))" }
              : {}),
          },
          "&::after": {
            content: { xs: undefined, md: '""' },
            position: "absolute",
            top: 0,
            right: -50,
            height: "100%",
            width: "50px", // Adjust as needed
            backgroundColor: "#0d2a43",
            WebkitMask:
              "radial-gradient(farthest-side at bottom right, transparent 99%, #fff 100%)",
            mask: "radial-gradient(farthest-side at bottom right, transparent 99%, #fff 100%)",
            ...(isHome
              ? { filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))" }
              : {}),
          },
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            width: "100%",
            justifyContent: { md: "space-between", xs: "center" },
            gap: 1,
            flexDirection: { md: "row", xs: "column" },
            alignItems: { md: "center", xs: "flex-start" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: { xs: "100%", md: "auto" },
              justifyContent: { xs: "space-between", md: "flex-start" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: { xs: 1, md: 1 },
                alignItems: "center",
              }}
            >
              <IconButton href={"/"} aria-label="MTG Guru Lotus logo">
                <MtgGuruLogoIcon fontSize={"large"} />
              </IconButton>
              <Typography
                variant="caption"
                fontSize={24}
                noWrap
                component="div"
                color="primary"
              >
                {BRAND_NAME}
              </Typography>
            </Box>
            <Box
              sx={{
                display: mdUp ? "flex" : "none",
                // justifyContent: "center",
                // gap: 1,
                width: "100%",
                transition: "all 0.3s ease",
                paddingBottom: mdUp ? 0 : 1,
                gap: 1,
              }}
            >
              <Skeleton variant="rectangular" width={24} height={24} />
              <Skeleton variant="rectangular" width={24} height={24} />
            </Box>
          </Box>
          <Box
            sx={{
              display: { md: "flex", xs: "none" },
              alignItems: "center",
              minWidth: "fit-content",
              gap: 1,
            }}
          >
            <Skeleton variant="rectangular" width={75} height={36} />
            <Skeleton variant="rectangular" width={75} height={36} />
          </Box>
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
};

export default AppBarSkeleton;
