// src/app/theme.ts
"use client";
import { ThemeOptions } from "@mui/material";
import createTheme from "@mui/material/styles/createTheme";
import {
  ConditionsPaletteColorOptions,
  LegalitiesPaletteColorOptions,
} from "modules/common/model/models";
import { CardConditionEnum } from "modules/cards/model/card";
import { Source_Sans_3 } from "next/font/google";

const inactiveScrollbarColor = "rgba(168,139,255,0.2)";
const activeScrollbarColor = "rgb(115,89,183)";

const sourceSansPro = Source_Sans_3({
  subsets: ["latin"],
  weight: "400",
});

declare module "@mui/material/styles" {
  interface Palette {
    cardLegalities: LegalitiesPaletteColorOptions;
    cardCondition: ConditionsPaletteColorOptions;
  }

  interface PaletteOptions {
    cardLegalities: LegalitiesPaletteColorOptions;
    cardCondition: ConditionsPaletteColorOptions;
  }
}
export const themeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "rgb(168,139,255)",
      contrastText: "#1c1c1c",
    },
    secondary: {
      dark: "#305357",
      main: "#70CAD1",
    },
    background: {
      default: "rgba(0,108,103,0.18)",
      paper: "#0d2a43",
    },
    text: {
      primary: "#CAFF8A",
      secondary: "rgba(113,187,176,1)",
    },
    warning: {
      main: "#F9F871",
    },
    success: {
      main: "#97d451",
    },
    error: {
      main: "#dc3545",
    },
    cardLegalities: {
      legal: "rgba(151,212,81,0.8)",
      banned: "rgba(220,53,69,0.8)",
      notLegal: "rgba(174,174,174,0.8)",
      restricted: "rgba(112,202,209,0.8)",
    },
    cardCondition: {
      [CardConditionEnum._]: "",
      [CardConditionEnum.Poor]: "rgba(255,99,132,0.9)",
      [CardConditionEnum.Played]: "rgb(255,140,105)",
      [CardConditionEnum.LightPlayed]: "rgb(255,178,128)",
      [CardConditionEnum.Good]: "rgb(255,218,155)",
      [CardConditionEnum.Excellent]: "rgb(40,167,69)",
      [CardConditionEnum.NearMint]: "rgb(92,214,124)",
      [CardConditionEnum.Mint]: "rgb(174,240,117)",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: sourceSansPro.style.fontFamily,
    h1: {
      fontSize: "2.25rem", // 40px
      lineHeight: 1.2,
      marginBottom: "0.25em",
    },
    h2: {
      fontSize: "2rem", // 32px
      lineHeight: 1.3,
    },
    h3: {
      fontSize: "1.75rem", // 28px

      lineHeight: 1.4,
    },
    h4: {
      fontSize: "1.5rem", // 24px

      lineHeight: 1.5,
    },
    h5: {
      fontSize: "1.25rem", // 20px
      lineHeight: 1.6,
    },
    h6: {
      fontSize: "1rem", // 16px
      lineHeight: 1.7,
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 3,
      },
      styleOverrides: {
        root: {
          backgroundImage: "unset",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + $track": {
              opacity: 1,
              border: "none",
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          border: "1px solid #bdbdbd",
          backgroundColor: "#fafafa",
          opacity: 1,
          transition:
            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #0d2a43 inset",
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        content: {
          "& .MuiCardHeader-content": {
            flex: "1 1 auto",
            width: "100%",
            display: "contents",
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        disableScrollLock: true,
      },
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            backgroundColor: "#9371ff",
            color: "#000000",
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // backgroundColor: "rgba(147,113,255,.3)",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        body: {
          // height: "100dvh",
          /*marginTop: 64,*/
          // height: "calc(100vh - 64px)",
          // maxHeight: "calc(100vh - 64px)",
          // height: "calc(100vh - 128px)",
          // maxHeight: "calc(100vh - 128px)",
          // maxHeight: "calc(100vh - 128px)",
          overflow: "scroll",
          overflowX: "hidden",
          /*[theme.breakpoints.down("md")]: {
            marginTop: 56,
            maxHeight: "calc(100vh - 56px)",
            height: "calc(100vh - 56px)",
          },*/
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "transparent",
            width: "14px",
            height: "14px",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(112,202,209,0.3)",
            backgroundClip: "padding-box",
            borderRadius: 8,
            transition: "background-color 0.3s ease",
            border: "4px solid rgba(0, 0, 0, 0)",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: activeScrollbarColor,
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: activeScrollbarColor,
            },
          "&::-webkit-scrollbar-thumb:vertical:window-inactive, & *::-webkit-scrollbar-thumb:vertical:window-inactive":
            {
              backgroundColor: inactiveScrollbarColor,
            },
          "&::-webkit-scrollbar-thumb:horizontal:window-inactive, & *::-webkit-scrollbar-thumb:horizontal:window-inactive":
            {
              backgroundColor: inactiveScrollbarColor,
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: activeScrollbarColor,
          },
          // show scrollbars when scrolling
          "&:hover::-webkit-scrollbar, & *:hover::-webkit-scrollbar": {
            backgroundColor: "transparent",
          },
          "&:hover::-webkit-scrollbar-thumb, & *:hover::-webkit-scrollbar-thumb":
            {
              backgroundColor: "rgba(112,202,209,1)",
            },
          "&:hover::-webkit-scrollbar, & *:hover::-webkit-scrollbar, &::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb":
            {
              transition: "background-color 0.3s ease",
            },
          "&::-webkit-scrollbar-thumb:vertical, & *::-webkit-scrollbar-thumb:vertical":
            {
              transition: "background-color 0.3s ease",
            },
          "&::-webkit-scrollbar-thumb:horizontal, & *::-webkit-scrollbar-thumb:horizontal":
            {
              transition: "background-color 0.3s ease",
            },
          "&:hover::-webkit-scrollbar-thumb:vertical, & *:hover::-webkit-scrollbar-thumb:vertical":
            {
              backgroundColor: activeScrollbarColor,
            },
          "&:hover::-webkit-scrollbar-thumb:horizontal, & *:hover::-webkit-scrollbar-thumb:horizontal":
            {
              backgroundColor: activeScrollbarColor,
            },
        },
        /*`
        @font-face {
          font-family: 'Raleway';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Raleway'), local('Raleway-Regular'), url(${RalewayWoff2}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `*/
      }),
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #305357",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #305357",
          "&:last-child": {
            border: "none",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#70CAD1",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popupIndicator: {
          color: "#70CAD1",
        },
        clearIndicator: {
          color: "#70CAD1",
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);
