import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ConfirmDeleteModal } from "modules/common/components/ConfirmDeleteModal";
import { SnackbarProvider } from "notistack";
import { Theme, useMediaQuery } from "@mui/material";

const LocalSnackbarProvider = (props: { children: any }) => {
  const { children } = props;
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <SnackbarProvider
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: mdDown ? "top" : "bottom",
        horizontal: "center",
      }}
      maxSnack={3}
    >
      <CssBaseline />
      {children}
      <ConfirmDeleteModal />
    </SnackbarProvider>
  );
};

export default LocalSnackbarProvider;
