import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { ReplacedSymbolsOracleText } from "modules/common/components/ManaFontSymbols";
import { MagicCard } from "modules/cards/model/card";

export interface Props {
  card: MagicCard;
}

export default function CardRulings(props: Props) {
  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Typography variant={"h5"} sx={{ my: 1 }}>
        Rulings
      </Typography>
      {props.card.rulings?.length ? (
        <Grid container pb={2}>
          {props.card.rulings?.length
            ? props.card.rulings.map((ruling, idx: number) => {
                return (
                  <Grid key={idx} item xs={12} container direction={"column"}>
                    <Grid item>
                      <Typography color={"secondary.main"} variant={"caption"}>
                        {new Date(ruling.date).toLocaleDateString()}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ReplacedSymbolsOracleText
                        variant={"body2"}
                        oracleText={ruling.text}
                        style={{
                          fontSize: "0.70em",
                          marginLeft: "1px",
                          color: "primary.main",
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })
            : null}
        </Grid>
      ) : (
        <Box my={1}>
          <Typography
            variant={"caption"}
            sx={{ fontStyle: "italic" }}
            color={"secondary"}
          >
            No rulings for this card
          </Typography>
        </Box>
      )}
    </>
  );
}
