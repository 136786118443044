"use client";

import { QueryClientProvider } from "@tanstack/react-query";
import React, { ReactNode } from "react";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { getQueryClient } from "modules/common/getQueryClient";

export default function RQProvider({ children }: { children: ReactNode }) {
  const queryClient = getQueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      {children}

      {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      <ProgressBar
        height="4px"
        color="#F9F871"
        options={{ showSpinner: false }}
        shallowRouting
      />
    </QueryClientProvider>
  );
}
