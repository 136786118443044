"use client";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuth, useUser } from "@clerk/nextjs";
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import Link from "modules/common/components/navigation/Link";
import {
  Inbox,
  Logout,
  ReceiptLong as ReceiptLongIcon,
  Settings,
  Store,
} from "@mui/icons-material";
import { ShortUser } from "modules/common/model/models";
import { useNotification } from "modules/chat/hooks/useNotification";
import { DecksIcon } from "modules/common/components/Icons/DecksIcon";
import { CollectionIcon } from "modules/common/components/Icons/CollectionIcon";
import { clerkImage } from "utils/utils";

interface UserAvatarProps {
  width?: number;
  height?: number;
  appUser: ShortUser;
}

export const UserAvatar = ({
  width = 32,
  height = 32,
  appUser,
}: UserAvatarProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const { signOut } = useAuth();
  const { user, isLoaded } = useUser();
  const theme = useTheme();
  const { latestNotification } = useNotification();
  const clerkImageUrl = clerkImage(appUser.preferredAvatar, height, width);
  useEffect(() => {
    if (open && anchorRef.current) {
      anchorRef.current.focus();
    }
  }, [open]);

  const handleToggle = useCallback(() => setOpen((prev) => !prev), []);
  const handleClose = useCallback((event: Event | React.SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) return;
    setOpen(false);
  }, []);
  const handleSignOut = useCallback(() => {
    setOpen(false);
    signOut();
  }, [signOut]);

  const handleListKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === "Tab" || event.key === "Escape") {
      event.preventDefault();
      setOpen(false);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexGrow: 1,
        padding: 1,
      }}
    >
      {isLoaded && (
        <Box
          ref={anchorRef}
          onClick={handleToggle}
          sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <Tooltip
            title={
              <Box textAlign={"center"}>
                Open the User menu <br />
                (Logged in as {appUser.username})
              </Box>
            }
          >
            <Badge
              color="error"
              variant="dot"
              invisible={latestNotification?.status !== "unread"}
            >
              <Avatar
                sx={{
                  bgcolor: "#fff",
                  width,
                  height,
                  border: `2px solid ${theme.palette.primary.main}`,
                }}
                alt={user?.username || ""}
                src={clerkImageUrl}
              />
            </Badge>
          </Tooltip>
        </Box>
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-end" ? "right top" : "right bottom",
            }}
          >
            <Paper
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <ListSubheader
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      lineHeight: "24px", // Adjust as needed
                      fontSize: "16px", // Adjust as needed
                      fontWeight: "600",
                    }}
                  >
                    YOUR STUFF
                  </ListSubheader>
                  <MenuItem
                    component={Link}
                    href={
                      latestNotification
                        ? `/inbox/${latestNotification.chatId}`
                        : "/inbox"
                    }
                    onClick={handleClose}
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    <ListItemIcon>
                      <Badge
                        color="error"
                        variant="dot"
                        invisible={latestNotification?.status !== "unread"}
                      >
                        <Inbox
                          fontSize="small"
                          sx={{ color: theme.palette.primary.contrastText }}
                        />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText>Inbox</ListItemText>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    href="/decks/personal"
                    onClick={handleClose}
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    <ListItemIcon>
                      <DecksIcon
                        fontSize="small"
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText>Decks</ListItemText>
                  </MenuItem>

                  <MenuItem
                    component={Link}
                    href="/trade/stores/personal"
                    onClick={handleClose}
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    <ListItemIcon>
                      <Store
                        fontSize="small"
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText>Stores</ListItemText>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    href="/trade/lists/personal"
                    onClick={handleClose}
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    <ListItemIcon>
                      <ReceiptLongIcon
                        fontSize="small"
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText>Trade Lists</ListItemText>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    href="/collection"
                    onClick={handleClose}
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    <ListItemIcon>
                      <CollectionIcon
                        fontSize="small"
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText>Collection</ListItemText>
                  </MenuItem>
                  <Divider
                    sx={{ bgcolor: "primary.contrastText" }}
                    component="li"
                  />
                  <ListSubheader
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      lineHeight: "24px", // Adjust as needed
                      fontSize: "16px", // Adjust as needed
                      fontWeight: "600",
                    }}
                  >
                    PROFILE & SETTINGS
                  </ListSubheader>

                  <MenuItem
                    component={Link}
                    href={`/users/${user?.username}`}
                    onClick={handleClose}
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    <ListItemIcon>
                      <Tooltip title="Early Adopter">
                        <i
                          className="ms ms-planeswalker"
                          style={{
                            fontSize: "16pt",
                            color: theme.palette.primary.contrastText,
                          }}
                        />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText>Profile</ListItemText>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    href="/settings?tab=all"
                    onClick={handleClose}
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    <ListItemIcon>
                      <Settings
                        fontSize="small"
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText>Account Settings</ListItemText>
                  </MenuItem>
                  <Divider
                    sx={{ bgcolor: "primary.contrastText" }}
                    component="li"
                  />
                  <MenuItem
                    onClick={handleSignOut}
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    <ListItemIcon>
                      <Logout
                        fontSize="small"
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
