import createSvgIcon from "@mui/material/utils/createSvgIcon";

export const MtgGuruLogoIcon = createSvgIcon(
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 590 595"
    width="590"
    height="595"
  >
    <path
      fill="#1c1c1c"
      d="m295.3 583.5c-158.9 0-287.3-128.4-287.3-287.2 0-158.9 128.4-287.3 287.3-287.3 158.8 0 287.2 128.4 287.2 287.3 0 158.8-128.4 287.2-287.2 287.2z"
    />
    <path
      opacity=".2"
      fill="#006c67"
      d="m295.3 583.5c-158.9 0-287.3-128.4-287.3-287.2 0-158.9 128.4-287.3 287.3-287.3 158.8 0 287.2 128.4 287.2 287.3 0 158.8-128.4 287.2-287.2 287.2z"
    />
    <path
      fill="#a88bff"
      fillRule="evenodd"
      d="m317.2 6.1c16 1 39.2 4.5 53.8 8 36.2 8.7 74.8 27.7 105 51.7 12.6 10 37.7 35.2 47.8 47.9 25 31.6 43.3 67 53.2 102.6 9 32.7 11.5 53.7 10.7 89.7-0.7 29.3-2.4 43.3-7.6 64-10.9 42.9-27.5 76.1-55.7 111.5-10.3 12.8-31.1 34.4-40.3 41.8-21.9 17.6-52.7 36.1-75.1 45-30.4 12.2-59.3 18.5-95.1 20.7-33.4 2-71.5-2.4-103.4-12.2-65.3-20-115.2-55.6-153.7-109.8-28.3-39.8-43.1-75.4-50.7-122-5-30.5-5.2-55.1-0.6-85.5 5-33.9 8.3-47.1 17.9-71.5 7.8-19.7 14.1-31.5 27.7-51.9 18.8-28.4 37.4-49.1 59.9-66.9 11-8.7 35.3-24.8 44-29.2 10.8-5.4 34.1-15.3 44.5-19 21.4-7.4 51.6-13.2 77-14.9 17.9-1.2 23.8-1.2 40.7 0zm-15.2 565.2l11.2-0.7c56.8-3.4 111.3-24 153.3-57.9 13.9-11.2 31.2-28.5 42.1-42.1 30.1-37.4 47-73.2 56.9-120.6 4.5-21.2 5.9-61 3-83.5-6.3-48.5-20.3-85.4-47.3-124.9-25.9-37.7-61.5-69-101.8-89.3-15.4-7.8-24.6-11.5-40.9-16.6-11.5-3.6-40.6-8.9-58.4-10.7-11.7-1.1-35.9-1.1-50.4 0.1-45.8 3.8-94.4 20.9-130.5 46.1-22.2 15.5-46.4 38-58.8 54.8-18.1 24.5-27.7 39.5-31.8 50-1 2.5-2.1 4.9-2.6 5.5-2 2.4-10.2 25.3-13.9 39-12.3 45.3-13.9 100.1-4 140.6 4.1 16.9 12 40.2 17.4 51.5 17 35.4 48.3 75 77.5 98 10.1 7.9 29.8 21.2 39 26.2 16.2 8.9 46.7 20.6 66.9 25.7 12.6 3.1 33.4 6.5 48.2 7.8 4 0.4 7.6 0.7 10.5 0.7 0.9 0 14.4 0.3 14.4 0.3z"
    />
    <filter id="f0">
      <feFlood floodColor="#a88bff" floodOpacity="1" />
      <feBlend mode="normal" in2="SourceGraphic" />
      <feComposite in2="SourceAlpha" operator="in" />
    </filter>
    <g filter="url(#f0)">
      <path
        fill="#305357"
        d="m215.5 119.4c-7.2 13.2-12.2 25.2-14 33.5-2.2 10.8-2.3 10.5 4.9 13.9 8.7 4.1 23.2 12.7 28.2 16.8l4.1 3.3 1.9-4.2c3.4-7.5 15.7-25 22.2-31.6l6.4-6.3-3.9-4c-8.3-8.6-21.5-17.9-38.3-27l-6.5-3.5z"
      />
      <path
        fill="#305357"
        d="m359.5 116.8c-14 7.3-24.4 14.5-31.8 22l-5.8 5.8 3.4 3.6c6.8 7.2 15.6 19.7 26.6 37.8 0.7 1.2 1.7 0.7 5.2-2.7 5.1-4.9 17.4-12.5 27.2-16.8l6.8-3-0.5-4c-1.1-8.8-6-22.6-12.3-34.5-3.5-6.9-6.8-12.8-7.1-13.2-0.4-0.4-5.7 1.9-11.7 5z"
      />
      <path
        fill="#305357"
        d="m288.9 143.7c-25.8 26.6-44 59.1-50.3 89.6-7.7 37.4-1.9 68.9 17.4 94.6 8.9 11.8 17.2 18.2 28.4 22.1l5.1 1.7v-47.6c0-48.9 0.3-53.3 4.7-77.9 1.2-6.4 2.8-8.4 2.8-3.3 0 1.5 1 8.6 2.1 15.7 1.9 11.4 2.2 18.7 2.8 62.1 0.3 27 0.9 49.4 1.2 49.8 0.4 0.3 4.2-1.3 8.5-3.7 18.1-10 33.5-31.8 39.8-56.4 4.1-15.9 3.8-44.7-0.5-62.4-3.9-15.7-12.7-35.6-22.1-49.8-7.5-11.2-31.3-41.2-32.7-41.2-0.4 0-3.6 3-7.2 6.7z"
      />
      <path
        fill="#305357"
        d="m144 176.8c0.1 35.7 4.5 55.9 18.6 84.7 5.2 10.6 7.6 14.2 18.7 27.3 7.9 9.4 24.5 24.4 34.3 31 6.4 4.3 25.5 14.4 26.1 13.8 0.2-0.2-2-3.8-4.9-8.2-11-16.7-15.8-32.9-16.5-56.4-0.9-26.1 3.7-51.1 11.7-63.7 1.6-2.5 2.1-2.1-12.9-12.8-19.1-13.8-41.9-25.1-64.6-32.1-4.4-1.4-8.6-2.7-9.2-2.9-1-0.3-1.3 3.9-1.3 19.3z"
      />
      <path
        fill="#305357"
        d="m431 162.1c-23.2 7.7-56 25.3-69.2 37.2l-3.7 3.3 3 7.5c6.2 15.2 8.1 25.4 8.6 47.1 0.9 33.9-1.6 43.6-17.6 70l-2.3 3.7 5.4-1.5c14.5-4.2 40.3-24.9 57.2-45.8 15.8-19.5 24-36.9 30-63.9 3.2-14 5.4-44.4 4-55.3l-0.9-7.1z"
      />
      <path
        fill="#305357"
        d="m81 238.9c-10.7 1.7-23.1 4.1-23.8 4.7-0.2 0.1 0.5 1.9 1.6 4.1 1.1 2.1 3.9 8.6 6.2 14.3 17.7 44.7 52.2 81.5 92.1 98.1 22.6 9.4 55.8 13.1 78.9 8.7 5.4-1 27.4-7.3 29.1-8.4 1.2-0.7 1.1-1.7-0.3-3.7-0.8-1-4.9-2.4-10.6-3.7-23.9-5-38.5-13.2-65.7-36.5-22.8-19.5-39.6-43.7-45.6-65.3-2.1-7.9-2.3-8.2-5.8-9.2-16.6-4.4-39.9-5.7-56.1-3.1z"
      />
      <path
        fill="#305357"
        d="m475.2 240.1c-7.9 0.9-24.4 3.7-24.7 4.1-0.1 0.2-1.2 3.2-2.4 6.8-3.2 9.2-13.2 28-18.8 35.3-7.3 9.7-19.5 23.7-25.4 29.3-9.9 9.3-26.1 20.6-37.4 26-10.8 5.2-26.4 10.4-31.1 10.4-3.3 0-8.6 2.3-10.4 4.5-1.3 1.5-1.2 1.9 0.8 3.5 5.4 4.4 26 9 44.7 9.9 33.7 1.8 54.7-2.9 79.9-17.9 17.6-10.5 30.3-21.9 44.8-40 4-5.1 13.9-19.9 18.3-27.5 6.6-11.3 19.6-38.8 18.7-39.7-0.4-0.4-6.4-1.9-13.3-3.3-13.2-2.6-28.9-3.1-43.7-1.4z"
      />
      <path
        fill="#305357"
        d="m285 366.4c-1.9 0.7-8.8 3.9-15.2 7-17.5 8.5-37 12.6-59.7 12.6-12.6 0-42.5-5.5-53.3-9.7-3.4-1.4-3.9-1.3-6.5 0.7-2.2 1.6-5.8 2.4-14.9 3.5-6.5 0.8-12.8 1.5-13.9 1.5-2.8 0 14.4 17.2 26.5 26.4 9.8 7.4 17.2 11 29.5 14.5 13.2 3.7 19.1 4.4 33.8 3.9 13-0.5 15.1-0.8 25.2-4.2 14.5-4.9 23.2-10.2 33-20.1 11.3-11.4 18-21.6 19.4-29.6 0.6-3.5 0.7-6.6 0.3-7-0.4-0.4-2.3-0.2-4.2 0.5z"
      />
      <path
        fill="#305357"
        d="m300 366.7c0 2.3 10.1 20.6 15.2 27.4 9.9 13.4 20.5 21 37.3 26.9 12.8 4.5 21.9 6 36.1 6 28.4 0 47.1-8.3 70.9-31.6l11-10.8-9-0.8c-9.4-0.8-22.5-4.3-22.5-6 0-1.3-2.8-0.8-19.5 3.7-12.7 3.4-15.5 3.8-30 4.2-11.7 0.4-19.6 0.1-29.1-1.1-16.9-2.2-26.3-5.1-43.1-13.5-8.1-4-17.3-6.4-17.3-4.4z"
      />
      <path
        fill="#305357"
        d="m184.2 472.9c-10.7 0.5-12.6 0.9-13.8 2.5-3.9 5.4 1 8.8 12.4 8.6 4.2-0.1 10.9 0.2 14.7 0.6 3.9 0.5 18.6 0.7 32.7 0.4 24.1-0.5 25.8-0.6 28.5-2.6 1.5-1.2 2.9-3.2 3.1-4.6 0.6-5.1-0.5-5.3-34.2-5.4-17.1 0-36.7 0.2-43.4 0.5z"
      />
      <path
        fill="#305357"
        d="m338 472.9c-5.6 0.5-6.6 0.9-7.9 3.3-1.3 2.3-1.3 2.8-0.1 4.4 3.4 4 6.7 4.4 37.2 4.4 16.1 0 31.1-0.5 33.3-1 7-1.6 10.3-9 4.9-11-2.7-1.1-56-1.1-67.4-0.1z"
      />
    </g>
  </svg>,
  "MtgGuruLogoIcon"
);
