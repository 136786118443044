"use client";
import React, { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import NotificationComponent from "modules/chat/components/Notification";

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "mdUp",
})<{
  open?: boolean;
  mdUp?: boolean;
}>(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  // paddingBottom: theme.spacing(4),
  marginLeft: 0,
  marginRight: 0,
  flex: "1 1 auto",
  /* ...(mdUp
    ? { marginLeft: `200px` }
    : {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }),*/
}));

interface MainProps {
  children: ReactNode;
}

export const MainComponent = ({ children }: MainProps) => {
  return (
    <Main>
      <NotificationComponent />
      {children}
    </Main>
  );
};
