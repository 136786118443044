"use client";
import {
  Box,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Star } from "@mui/icons-material";
import React from "react";
import { useSets } from "modules/common/hooks/sets";
import { AutocompleteMagicCard } from "modules/cards/model/card";
import { TooltipCardImage } from "modules/common/components/TooltipCardImage";

interface Props {
  option: AutocompleteMagicCard;
  props: React.HTMLAttributes<HTMLLIElement>;
}

export default function AutocompleteListItem({ props, option }: Props) {
  const { query } = useSets();
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <TooltipCardImage card={option}>
      <ListItem {...props} key={option.id}>
        <Box
          display="flex"
          gap={mdDown ? 1 : 2}
          flexGrow={1}
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <ListItemText
              primary={option.name}
              secondary={
                option.price && option.price.default ? (
                  <Typography variant="subtitle2" color="secondary">
                    {`€ ${option.price.default.toFixed(2)}`}
                  </Typography>
                ) : (
                  <>&nbsp;</>
                )
              }
            />
          </Box>
          <Tooltip title={query.data && query.data[option.setCode]?.name}>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <ListItemIcon
                sx={{ justifyContent: "flex-end", minWidth: "auto" }}
              >
                {option.isPromo && (
                  <Box
                    component="span"
                    sx={{
                      position: "absolute",
                      color: "text.secondary",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      zIndex: 100,
                      top: 5,
                      right: 10,
                    }}
                  >
                    <Star
                      color={"primary"}
                      sx={{
                        height: "12px",
                        width: "12px",
                      }}
                    />
                  </Box>
                )}
                <Icon
                  color={"secondary"}
                  baseClassName={`ss ss-${
                    option.isPromo
                      ? option.setCode.slice(1).toLowerCase()
                      : option.setCode.toLowerCase()
                  }`}
                />
              </ListItemIcon>
              <Typography
                variant="caption"
                color="secondary"
                component="span"
                sx={{ fontSize: "0.7rem" }}
              >
                {option.number}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      </ListItem>
    </TooltipCardImage>
  );
}
