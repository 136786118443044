"use client";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CardDetailsLegalities from "modules/common/components/CardDetailsDialog/CardDetailsLegalities";
import { Divider, Theme, useMediaQuery } from "@mui/material";
import { CardImageText } from "modules/common/components/CardDetailsDialog/CardImageText";
import CardRulings from "modules/common/components/CardDetailsDialog/CardDetailsRulings";
import { useRecoilState } from "recoil";
import { cardDetailsModalAtom } from "modules/cards/recoil/cards";
import { useRouter } from "next-nprogress-bar";
import { encodedCardName } from "utils/utils";
import { FinishEnum } from "modules/cards/model/card";
import { Transition } from "modules/common/components/dialog/Transition";

export interface CardDetailsDialogProps {}

export default function CardDetailsDialog() {
  const [{ open, card }, setCardDetailsDialog] =
    useRecoilState(cardDetailsModalAtom);
  const router = useRouter();
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const handleClose = () => {
    setCardDetailsDialog({
      card,
      open: false,
    });
  };

  const handleMore = () => {
    setCardDetailsDialog({
      card,
      open: false,
    });
    router.push(
      `/sets/${card.setCode}/${encodedCardName(card.name)}/${card.number}`
    );
    // router.push(`/sets/${card.setCode}/cards/${card.[id]}`);
  };

  return (
    <>
      <Dialog
        fullScreen={mdDown}
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            overflowX: "hidden",
          }}
        >
          <CardImageText
            card={card}
            isFoil={!card?.finishes?.includes(FinishEnum.NonFoil)}
          />
          <Divider sx={{ mb: 3, mt: 3 }} />
          <CardDetailsLegalities legalities={card.legalities} />
          <CardRulings card={card} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMore} variant={"contained"}>
            More
          </Button>
          <Button
            onClick={handleClose}
            // autoFocus
            variant={"outlined"}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
