import React from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Link from "modules/common/components/navigation/Link";
import { VersionTagRibbon } from "modules/common/components/VersionTagRibbon";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "background.default",
        mt: 2,
      }}
    >
      <Container maxWidth={"xl"}>
        <Box sx={{ px: 1, py: 2 }}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              sm={4}
              gap={1}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography fontSize={24} gutterBottom>
                Copyright Disclaimer
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Magic The Gathering is a registered trademark of Wizards of the
                Coast, Inc. Magic: The Gathering and all magic images are
                copyright © Wizards of the Coast, Inc.
              </Typography>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  &copy; 2024 MTG GURU. All rights reserved.
                </Typography>
                <VersionTagRibbon />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography fontSize={24} gutterBottom>
                  MTG GURU
                </Typography>
                <MuiLink component={Link} href="/about" underline="hover">
                  About
                </MuiLink>
                <MuiLink component={Link} href="/privacy" underline="hover">
                  Privacy
                </MuiLink>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontSize={24} gutterBottom>
                Follow Us
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <IconButton
                  color="inherit"
                  component={Link}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  href="facebook.com"
                  aria-label="https://facebook.com"
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  component={Link}
                  href="twitter.com"
                  target={"_blank"}
                  aria-label="https://twitter.com"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  target={"_blank"}
                  component={Link}
                  href="instagram.com"
                  aria-label="https://instagram.com"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
