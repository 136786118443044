"use client";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { theme } from "modules/common/components/ThemeRegistry/theme";
import LocalSnackbarProvider from "modules/common/components/ThemeRegistry/LocalSnackbarProvider";

// This implementation is from emotion-js
// https://github.com/emotion-js/emotion/issues/2928#issuecomment-1319747902
export default function ThemeRegistry(props: { children: any }) {
  const { children } = props;
  return (
    <ThemeProvider theme={theme}>
      <LocalSnackbarProvider>{children}</LocalSnackbarProvider>
    </ThemeProvider>
  );
}
