import { MagicCard } from "modules/cards/model/card";
import { atom } from "recoil";

interface CardDetailsModalAtom {
  open: boolean;
  card: MagicCard;
}

export const cardDetailsModalAtom = atom<CardDetailsModalAtom>({
  key: "cardDetailsModalAtom",
  default: {
    open: false,
    card: {} as MagicCard,
  },
});
