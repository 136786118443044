"use client";
import React, { useState, useTransition } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { LoadingButton } from "@mui/lab";
import { UseMutationResult } from "@tanstack/react-query";
import { TradeListType } from "modules/tradeLists/model/tradeList";
import { Transition } from "modules/common/components/dialog/Transition";

type ServerActionWithId = {
  id: string;
  action: (id: string, req: any, type?: TradeListType) => Promise<any>;
  req: any;
  type?: TradeListType;
  after?: () => void;
};

type ServerActionWithoutId = {
  action: (type: TradeListType, req: any) => Promise<any>;
  req: any;
  type: TradeListType;
  after?: () => void;
};

type ServerAction = ServerActionWithId | ServerActionWithoutId;

interface Props {
  open: boolean;
  name: string;
  confirmFunc?: any;
  loadingButton?: boolean;
  mutation?: {
    op: UseMutationResult<any, unknown, any, unknown>;
    req: any;
  };
  serverAction?: ServerAction;
  after?: () => void;
}

export const confirmDeleteModal = atom<Props>({
  key: "confirmDeleteModal",
  default: {
    open: false,
    name: "",
    confirmFunc: null,
    loadingButton: false,
  },
});

export const ConfirmDeleteModal = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    name,
    open,
    confirmFunc,
    loadingButton,
    mutation,
    serverAction,
    after,
  } = useRecoilValue(confirmDeleteModal);
  const setConfirmDelete = useSetRecoilState(confirmDeleteModal);
  const [isPending, startTransition] = useTransition();
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const handleYes = async () => {
    if (!mutation && !confirmFunc && !serverAction) {
      throw new Error("no confirm func or mutation supplied");
    }
    if (mutation) {
      setLoading(true);
      await mutation.op.mutateAsync(mutation.req);
      setLoading(false);
      handleClose();
    }
    if (confirmFunc) {
      if (loadingButton) {
        setLoading(true);
        confirmFunc().finally(() => {
          setLoading(false);
          handleClose();
        });
      } else {
        confirmFunc().finally(() => {
          handleClose();
        });
      }
    }
    if (serverAction) {
      startTransition(async () => {
        const { action, req, type } = serverAction;

        const actionPromise =
          "id" in serverAction
            ? (action as ServerActionWithId["action"])(
                serverAction.id,
                req,
                type
              )
            : (action as ServerActionWithoutId["action"])(type!, req);

        actionPromise.finally(() => {
          serverAction.after?.();
          handleClose();
        });
      });
    }

    if (after) {
      after();
    }
  };

  const handleClose = () => {
    setConfirmDelete({ name, confirmFunc, loadingButton, open: false });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={mdDown}
      TransitionComponent={Transition}
    >
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {name}?
          <br />
          <Typography
            sx={{ fontStyle: "italic", fontSize: "0.8em" }}
            variant={"body2"}
            component={"span"}
            color={"secondary.main"}
          >
            This is irreversible
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} onClick={handleClose}>
          No
        </Button>
        <LoadingButton
          loading={loading || mutation?.op.isPending || isPending}
          variant={"outlined"}
          onClick={handleYes}
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
