"use client";
import React from "react";
import { Box, Button, Divider, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { VersionTagRibbon } from "modules/common/components/VersionTagRibbon";
import Link from "modules/common/components/navigation/Link";
import { useAuth } from "@clerk/nextjs";
import { sidebarOpenAtom } from "modules/layout/recoil/atoms";
import { MobileMenu } from "modules/common/components/navigation/MobileMenu";

const drawerWidth = 200;

export const DrawerComponent = () => {
  const theme = useTheme();
  const [open, setOpen] = useRecoilState(sidebarOpenAtom);
  const { isSignedIn } = useAuth();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleCloseDrawer = () => setOpen(false);

  return (
    <Drawer
      component="nav"
      sx={{
        width: mdUp ? drawerWidth : 0,
        flexShrink: { md: 0 },
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          [theme.breakpoints.up("md")]: {
            position: "relative",
          },
          [theme.breakpoints.down("md")]: {
            position: "fixed",
            visibility: open ? "visible" : "hidden",
          },
        },
      }}
      variant={mdUp ? "permanent" : "temporary"}
      anchor={mdUp ? "left" : "right"}
      open={mdUp ? true : open}
      onClose={handleDrawerToggle}
    >
      {mdDown && !isSignedIn && (
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              marginBottom: 2,
            }}
          >
            <Link href="/sign-in" onClick={handleCloseDrawer}>
              <Button variant="outlined" fullWidth>
                Sign In
              </Button>
            </Link>
            <Link href="/sign-up" onClick={handleCloseDrawer}>
              <Button variant="contained" fullWidth>
                Register
              </Button>
            </Link>
          </Box>
        </Box>
      )}
      <Divider />
      <MobileMenu />
      <VersionTagRibbon />
    </Drawer>
  );
};
