"use client";
import React, { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { UserAvatar } from "modules/common/components/UserAvatar";
import { BRAND_NAME } from "utils/constants";
import { SignInButton, SignUpButton, useAuth } from "@clerk/nextjs";
import { sidebarOpenAtom } from "modules/layout/recoil/atoms";
import CardSearchAutocomplete from "modules/common/components/NavCardSearch/CardSearchAutocomplete";
import { Menu } from "modules/common/components/navigation/Menu";
import Container from "@mui/material/Container";
import { usePathname } from "next/navigation";
import { useAppUser } from "modules/auth/user/hooks/useAppUser";
import { MtgGuruLogoIcon } from "modules/common/components/Icons/MTGGuruIcon";

export const AppBarComponent = () => {
  const theme = useTheme();
  const [open, setOpen] = useRecoilState(sidebarOpenAtom);
  const [showSearch, setShowSearch] = useState(false);
  const { isSignedIn } = useAuth();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { appUser } = useAppUser();

  const pathName = usePathname();

  const isHome = pathName === "/";
  const handleSearchComplete = () => {
    if (mdDown) {
      setShowSearch(false);
    }
  };
  const handleDrawerToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleSearchToggle = () =>
    setShowSearch((prevShowSearch) => !prevShowSearch);

  return (
    <>
      <MuiAppBar
        position="sticky"
        sx={{
          // border: "1px solid red",
          bgcolor: isHome ? "transparent" : theme.palette.background.paper,
          ...(isHome ? { boxShadow: "none" } : {}),
        }}
      >
        <Container
          maxWidth={"xl"}
          sx={{
            bgcolor: theme.palette.background.paper,
            position: "relative",
            ...(isHome ? { boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)" } : {}),
            "&::before": {
              ...(isHome ? { content: { xs: undefined, md: '""' } } : {}),
              position: "absolute",
              top: 0,
              left: -64,
              height: "64px",
              width: "64px", // Adjust as needed
              backgroundColor: theme.palette.background.paper,
              WebkitMask:
                "radial-gradient(farthest-side at bottom left, transparent 99%, #fff 100%)",
              mask: "radial-gradient(farthest-side at bottom left, transparent 99%, #fff 100%)",
              ...(isHome
                ? { filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))" }
                : {}),
            },
            "&::after": {
              ...(isHome ? { content: { xs: undefined, md: '""' } } : {}),
              position: "absolute",
              top: 0,
              right: -50,
              height: "100%",
              width: "50px", // Adjust as needed
              backgroundColor: theme.palette.background.paper,
              WebkitMask:
                "radial-gradient(farthest-side at bottom right, transparent 99%, #fff 100%)",
              mask: "radial-gradient(farthest-side at bottom right, transparent 99%, #fff 100%)",
              ...(isHome
                ? { filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))" }
                : {}),
            },
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              width: "100%",
              justifyContent: mdUp ? "space-between" : "center",
              gap: 1,
              flexDirection: mdUp ? "row" : "column",
              alignItems: mdUp ? "center" : "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: { xs: "100%", md: "auto" },
                justifyContent: { xs: "space-between", md: "flex-start" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: { xs: 1, md: 0.5 },
                  alignItems: "center",
                }}
              >
                <IconButton href={"/"} aria-label="MTG Guru Lotus logo">
                  <MtgGuruLogoIcon fontSize={"large"} />
                </IconButton>
                <Typography
                  variant="caption"
                  fontSize={24}
                  noWrap
                  component="div"
                  color="primary"
                >
                  {BRAND_NAME}
                </Typography>
              </Box>
              {mdDown && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    color="primary"
                    aria-label="Toggle search"
                    onClick={handleSearchToggle}
                  >
                    {showSearch ? <CloseIcon /> : <SearchIcon />}
                  </IconButton>
                  {isSignedIn && appUser && (
                    <UserAvatar width={24} height={24} appUser={appUser} />
                  )}
                  <IconButton
                    color="inherit"
                    aria-label="Open mobile menu drawer"
                    onClick={handleDrawerToggle}
                    edge="end"
                  >
                    {open ? (
                      theme.direction === "ltr" ? (
                        <ChevronRightIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )
                    ) : (
                      <MenuIcon />
                    )}
                  </IconButton>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: mdUp ? "flex" : "none",
                // justifyContent: "center",
                // gap: 1,
                width: "100%",
                transition: "all 0.3s ease",
                paddingBottom: mdUp ? 0 : 1,
              }}
            >
              <Menu />
            </Box>
            {mdUp && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "fit-content",
                  gap: 1,
                }}
              >
                {isSignedIn && appUser ? (
                  <UserAvatar appUser={appUser} />
                ) : (
                  <>
                    <SignInButton mode="modal">
                      <Button variant="outlined">Sign In</Button>
                    </SignInButton>
                    <SignUpButton mode={"modal"}>
                      <Button variant="contained">Register</Button>
                    </SignUpButton>
                  </>
                )}
              </Box>
            )}
          </Toolbar>
          <Collapse in={showSearch} timeout="auto">
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                boxShadow: "inset 0 0 16px rgba(0, 0, 0, 0.40)",
                zIndex: theme.zIndex.appBar + 1,
                padding: theme.spacing(1),
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CardSearchAutocomplete
                styles={{ width: "100%", maxWidth: 340 }}
                size="small"
                onSearchComplete={handleSearchComplete}
              />
            </Box>
          </Collapse>
        </Container>
      </MuiAppBar>
    </>
  );
};
