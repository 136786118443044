"use client";

import React, { ReactNode } from "react";
import { Theme, useMediaQuery } from "@mui/material";
import { DrawerComponent } from "modules/layout/DrawerComponent";
import { MainComponent } from "modules/layout/Main";
import Footer from "modules/common/components/Footer";
import CardDetailsDialog from "modules/common/components/CardDetailsDialog/CardDetailsDialog";
import { useRecoilState } from "recoil";
import { cardDetailsModalAtom } from "modules/cards/recoil/cards";

interface SidebarLayoutProps {
  children: ReactNode;
  header?: ReactNode;
}

export const AppLayout = ({ children, header }: SidebarLayoutProps) => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [{ open, card }, setCardDetailsDialog] =
    useRecoilState(cardDetailsModalAtom);
  return (
    <>
      {mdDown && <DrawerComponent />}
      {header}
      <MainComponent>{children}</MainComponent>
      <Footer />
      {open && <CardDetailsDialog />}
    </>
  );
};
